






















































































































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import {
    apiLuckyDrawEdit,
    apiLuckyDrawDetail,
    apiLuckyDrawAdd,
    apiLuckyDrawGetPrizeType
} from '@/api/marketing/lucky_draw'
import { apiCouponLists } from '@/api/marketing/coupon'
import MaterialSelect from '@/components/material-select/index.vue'
import { deepClone } from '@/utils/util'
@Component({
    components: {
        MaterialSelect
    }
})
export default class LsLuckyDrawChange extends Vue {
    @Prop() index?: number // 编辑礼品行数
    @Prop() val?: object // 礼品数据{}
    @Prop() status?: string // 是否禁用
    @Prop() mode?: string // 模式
    @Prop({
        default: '编辑奖品'
    })
    title!: string //弹窗标题
    @Prop({
        default: '700px'
    })
    width!: string | number //弹窗的宽度
    @Prop({
        default: '20vh'
    })
    top!: string | number //弹窗的距离顶部位置
    /** S Data **/
    visible = false
    $refs!: {
        valueRef: any
    }
    form = {
        name: '', // 奖品名称
        image: '', // 奖品图片
        type: 0, // 奖品类型 // 0-未中奖; 1-积分; 2-优惠券; 3-余额;
        type_value: 0, // 奖品值
        type_desc: '', // 奖品描述
        num: 0, // 奖品数量
        probability: 0, // 中奖概率
        tips: '', // 中奖提示
        status: '',
        probability_desc: 0
    }

    coupon = []

    // 表单验证
    valueRules = {
        name: [
            {
                required: true,
                message: '请输入奖品名称',
                trigger: 'blur'
            }
        ],
        image: [
            {
                required: true,
                message: '请选择奖品图片',
                trigger: 'blur'
            }
        ],
        type: [
            {
                required: true,
                message: '请选择奖品类型',
                trigger: 'change'
            }
        ],
        tips: [
            {
                required: true,
                message: '请输入中奖提示',
                trigger: 'blur'
            }
        ],
        num: [
            {
                required: true,
                message: '请输入奖品数量',
                trigger: 'blur'
            },
            {
                validator: (rule: object, value: string, callback: any) => {
                    if (Number(value) <= 0) {
                        callback(new Error('请输入大于0的数'))
                    } else {
                        callback()
                    }
                },
                trigger: 'blur'
            }
        ],
        type_value: [
            {
                required: true,
                message: '请输入',
                trigger: 'blur'
            }
            // {
            // 	validator: (rule: object, value: string, callback: any) => {
            // 		if (Number(value) <= 0 && this.form.type != 2) {
            //
            //
            // 			callback(new Error('请输入大于0的数'))
            // 		} else {
            // 			callback();
            // 		}
            // 	},
            // 	trigger: 'blur'
            // },
        ],
        probability: [
            {
                required: true,
                message: '请输入中奖概率',
                trigger: 'blur'
            },
            {
                validator: (rule: object, value: string, callback: any) => {
                    if (Number(value) <= 0) {
                        callback(new Error('请输入大于0的数'))
                    } else {
                        callback()
                    }
                },
                trigger: 'blur'
            }
        ],
        probability_desc: [
            {
                required: true,
                message: '请输入中奖概率',
                trigger: 'blur'
            },
            {
                validator: (rule: object, value: string, callback: any) => {
                    if (Number(value) <= 0) {
                        callback(new Error('请输入大于0的数'))
                    } else {
                        callback()
                    }
                },
                trigger: 'blur'
            }
        ]
    }
    /** E Data **/

    // 0-未中奖; 1-积分; 2-优惠券; 3-余额;
    @Watch('val', {
        immediate: true
    })
    getValue(val: any) {
        // this.form = val
        this.form = deepClone(val)
    }

    @Watch('form.probability_desc', {
        immediate: true
    })
    getProbability(val: any) {
        if (this.mode == 'edit') {
            this.form.probability = val * 1
        }
    }

    @Watch('form.type', {
        immediate: true
    })
    getType(val: any) {
        if (val == 0) {
            this.form.type_desc = '未中奖'
        } else if (val == 1) {
            this.form.type_desc = '积分'
        } else if (val == 2) {
            this.form.type_desc = '优惠券'
        } else if (val == 3) {
            this.form.type_desc = '余额'
        }
    }

    goToCouponEdit() {
        window.open('/admin/coupon/edit', '_blank')
    }

    couponLists() {
        apiCouponLists({
            status: 2,
            get_type: 2
        }).then((res: any) => {
            this.coupon = res.lists
        })
    }

    /** S Methods **/
    onSubimt() {
        this.$refs.valueRef.validate((valid: boolean): any => {
            if (!valid) {
                return
            }

            this.form.type_value = this.form.type_value * 1

            this.$emit('setPrize', this.form, this.index)
            this.visible = false
        })
    }

    onTrigger() {
        this.couponLists()

        // if(this.form.status == '' || this.form.status == null) {
        // 	this.form.type = 0
        // }

        this.visible = true
    }

    // 关闭弹窗
    close() {
        this.visible = false

        // 重制表单内容
        // this.$set(this.form, 'name', '')
        // this.$set(this.form, 'image', '')
        // this.$set(this.form, 'type', 0)
        // this.$set(this.form, 'type_value', 0)
        // this.$set(this.form, 'num', 0)
        // this.$set(this.form, 'probability', 0)
        // this.$set(this.form, 'tips', '')
    }
    /** E Methods **/

    /** S Life Cycle **/
    /** E Life Cycle **/
}
