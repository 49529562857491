import { render, staticRenderFns } from "./ls-lucky-draw-change.vue?vue&type=template&id=4b64b284&scoped=true&"
import script from "./ls-lucky-draw-change.vue?vue&type=script&lang=ts&"
export * from "./ls-lucky-draw-change.vue?vue&type=script&lang=ts&"
import style0 from "./ls-lucky-draw-change.vue?vue&type=style&index=0&id=4b64b284&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4b64b284",
  null
  
)

export default component.exports