








































































































































































































































import { Component, Vue } from 'vue-property-decorator'
import {
    apiLuckyDrawEdit,
    apiLuckyDrawDetail,
    apiLuckyDrawAdd,
    apiLuckyDrawGetPrizeType
} from '@/api/marketing/lucky_draw'
import { PageMode } from '@/utils/type'
import LsPagination from '@/components/ls-pagination.vue'
import DatePicker from '@/components/date-picker.vue'
import LsLuckyDrawChange from '@/components/lucky-draw/ls-lucky-draw-change.vue'
import { deepClone } from '@/utils/util.ts'
@Component({
    components: {
        LsPagination,
        DatePicker,
        LsLuckyDrawChange
    }
})
export default class LuckyDrawEdit extends Vue {
    mode: string = PageMode.ADD // 当前页面【add: 添加 | edit: 编辑】
    identity: number | null = null // 当前编辑的ID  valid: mode = 'edit'

    status: number | null = null // 当前编辑的状态  valid: status = 0-未开始 1-进行中
    type = ''

    prizeType = 0 //  0-未中奖; 1-积分; 2-优惠券; 3-余额;

    form = {
        name: '', // 活动名称
        start_time: '', // 开始时间，时间戳
        end_time: '', // 结束时间，时间戳
        need_integral: 0, // 需要消耗的积分
        frequency_type: 0, // 抽奖次数类型
        frequency: 0, // 抽奖次数
        rule: '', // 抽奖规则
        show_winning_list: 0, // 是否显示中奖名单
        remark: '', // 备注
        prizes: [{}] // 奖品
        // prizes.name	: '', // 奖品名称
        // prizes.image: '', // 奖品图片
        // prizes.type: '', // 奖品类型
        // prizes.type_value: '', // 奖品类型值
        // prizes.num: '', // 奖品数量
        // prizes.probability: '', // 中奖概率
        // prizes.tips: '', // 中奖提示
    }

    $refs!: {
        formRef: any
    }
    formRules = {
        name: [
            {
                required: true,
                message: '请输入活动名称',
                trigger: 'blur'
            }
        ],
        start_time: [
            {
                required: true,
                message: '请选择活动时间',
                trigger: 'change'
            }
        ],
        end_time: [
            {
                required: true,
                message: '请选择活动时间',
                trigger: 'change'
            }
        ],
        need_integral: [
            {
                required: true,
                message: '请输入消耗积分',
                trigger: 'blur'
            }
        ],
        frequency_type: [
            {
                required: true,
                message: '请选择抽奖次数',
                trigger: 'change'
            }
        ],
        rule: [
            {
                required: true,
                message: '请输入抽奖规则',
                trigger: 'blur'
            }
        ],
        show_winning_list: [
            {
                required: true,
                message: '请选择中奖名单是否隐藏',
                trigger: 'blur'
            }
        ]
    }
    lists = [
        {
            name: '', // 奖品名称
            image: '', // 奖品图片
            type: 0, // 奖品类型 // 0-未中奖; 1-积分; 2-优惠券; 3-余额;
            type_value: 0, // 奖品值
            type_desc: '', // 奖品描述
            num: 0, // 奖品数量
            probability: 0, // 中奖概率
            tips: '', // 中奖提示
            status: '',
            probability_desc: 0
        },
        {
            name: '', // 奖品名称
            image: '', // 奖品图片
            type: 0, // 奖品类型 // 0-未中奖; 1-积分; 2-优惠券; 3-余额;
            type_value: 0, // 奖品值
            type_desc: '', // 奖品描述
            num: 0, // 奖品数量
            probability: 0, // 中奖概率
            tips: '', // 中奖提示
            status: '',
            probability_desc: 0
        },
        {
            name: '', // 奖品名称
            image: '', // 奖品图片
            type: 0, // 奖品类型 // 0-未中奖; 1-积分; 2-优惠券; 3-余额;
            type_value: 0, // 奖品值
            type_desc: '', // 奖品描述
            num: 0, // 奖品数量
            probability: 0, // 中奖概率
            tips: '', // 中奖提示
            status: '',
            probability_desc: 0
        },
        {
            name: '', // 奖品名称
            image: '', // 奖品图片
            type: 0, // 奖品类型 // 0-未中奖; 1-积分; 2-优惠券; 3-余额;
            type_value: 0, // 奖品值
            type_desc: '', // 奖品描述
            num: 0, // 奖品数量
            probability: 0, // 中奖概率
            tips: '', // 中奖提示
            status: '',
            probability_desc: 0
        },
        {
            name: '', // 奖品名称
            image: '', // 奖品图片
            type: 0, // 奖品类型 // 0-未中奖; 1-积分; 2-优惠券; 3-余额;
            type_value: 0, // 奖品值
            type_desc: '', // 奖品描述
            num: 0, // 奖品数量
            probability: 0, // 中奖概率
            tips: '', // 中奖提示
            status: '',
            probability_desc: 0
        },
        {
            name: '', // 奖品名称
            image: '', // 奖品图片
            type: 0, // 奖品类型 // 0-未中奖; 1-积分; 2-优惠券; 3-余额;
            type_value: 0, // 奖品值
            type_desc: '', // 奖品描述
            num: 0, // 奖品数量
            probability: 0, // 中奖概率
            tips: '', // 中奖提示
            status: '',
            probability_desc: 0
        },
        {
            name: '', // 奖品名称
            image: '', // 奖品图片
            type: 0, // 奖品类型 // 0-未中奖; 1-积分; 2-优惠券; 3-余额;
            type_value: 0, // 奖品值
            type_desc: '', // 奖品描述
            num: 0, // 奖品数量
            probability: 0, // 中奖概率
            tips: '', // 中奖提示
            status: '',
            probability_desc: 0
        },
        {
            name: '', // 奖品名称
            image: '', // 奖品图片
            type: 0, // 奖品类型 // 0-未中奖; 1-积分; 2-优惠券; 3-余额;
            type_value: 0, // 奖品值
            type_desc: '', // 奖品描述
            num: 0, // 奖品数量
            probability: 0, // 中奖概率
            tips: '', // 中奖提示
            status: '',
            probability_desc: 0
        }
    ]

    setPrize(obj: any, index: any) {
        // this.form.prizes[index] = deepClone(obj)
        this.$set(this.form.prizes, index, deepClone(obj))
        this.$forceUpdate()
    }

    checkPrizes() {
        let isPass = true
        // 验证礼品

        for (let i = 0; i < this.form.prizes.length; i++) {
            const type = (this.form.prizes[i] as any).type

            if ((this.form.prizes[i] as any).name == '') {
                this.$message.error(`请输入位置${i + 1}的奖品名称`)
                return (isPass = false)
            }
            if ((this.form.prizes[i] as any).image == '') {
                this.$message.error(`请选择位置${i + 1}的奖品图片`)
                return (isPass = false)
            }
            if ((this.form.prizes[i] as any).tips == '') {
                this.$message.error(`请输入位置${i + 1}的抽中提示语`)
                return (isPass = false)
            }
            if (type != 0 && !(this.form.prizes[i] as any).num) {
                this.$message.error(`请输入位置${i + 1}的奖品数量`)
                return (isPass = false)
            }
            if (type != 0 && !(this.form.prizes[i] as any).probability) {
                this.$message.error(`请输入位置${i + 1}的中奖概率`)
                return (isPass = false)
            }
            if (type != 0 && (this.form.prizes[i] as any).type_value == '') {
                this.$message.error(
                    `请输入位置${i + 1}的${
                        type == 1
                            ? '积分'
                            : type == 2
                            ? '优惠券'
                            : type == 3
                            ? '余额'
                            : ''
                    }`
                )
                return (isPass = false)
            }
        }

        return isPass
    }

    onSubmit() {
        this.$refs.formRef.validate((valid: boolean): any => {
            if (!valid) {
                return
            }

            this.$nextTick(() => {
                if (!this.checkPrizes()) {
                    return
                }

                // 提交请求
                if (this.mode == PageMode.ADD) {
                    this.luckyDrawAdd()
                } else if (this.mode == PageMode.EDIT) {
                    this.luckyDrawEdit()
                }
            })
        })
    }

    luckyDrawDetail() {
        apiLuckyDrawDetail({
            id: this.identity
        }).then((res: any) => {
            res.start_time = res.start_time_desc
            res.end_time = res.end_time_desc

            this.form = res
        })
    }

    luckyDrawEdit() {
        apiLuckyDrawEdit(this.form)
            .then((res: any) => {
                setTimeout(() => {
                    this.$router.go(-1)
                }, 500)
            })
            .catch((err: any) => {})
    }

    luckyDrawAdd() {
        apiLuckyDrawAdd(this.form)
            .then((res: any) => {
                setTimeout(() => {
                    this.$router.go(-1)
                }, 500)
            })
            .catch((err: any) => {})
    }

    created() {
        const query: any = this.$route.query

        if (query.mode) {
            this.mode = query.mode
        }

        // 编辑模式：初始化数据
        if (this.mode === PageMode.EDIT) {
            this.identity = query.id * 1
            this.status = query.status
            this.type = query.type
            this.luckyDrawDetail()
        } else {
            this.form.prizes = this.lists
        }
    }
}
